<template>
  <div>
    <div class="black text-h6 white--text rounded pa-4 pl-5">
      {{ $t('api.api_token') }}
    </div>
    <div class="white rounded px-5 py-4 mt-4">
      <div class="text-h6">
        {{ $t('api.api_token_desc') }}
      </div>
      {{ $t('api.api_integration_before', { name: adNetwork.name }) }}
      <component :is="linkOrText" :href="adNetwork.settings.apiUrl" target="_blank">
        {{ $t('api.documentation') }}
      </component>
      {{ $t('api.api_integration_after', { name: adNetwork.name }) }}
    </div>
    <v-container fluid class="white rounded px-5 py-4 mt-4">
      <v-row no-gutters>
        <v-col>
          <c-text-field
            :value="token"
            :label="$t('api.your_token')"
            :placeholder="$t('api.no_tokens')"
            label-bold
            height="46"
            hide-details
            readonly
          >
            <template v-if="token" #append>
              <copy-button :text-copied="token" />
              <c-btn
                icon
                color="error"
                :loading="removeTokenIsLoading"
                :icon-props="{
                  icon: '$close',
                  size: '18'
                }"
                @click="$refs.removeAcceptDialog.ask(token)"
              />
            </template>
          </c-text-field>
        </v-col>
        <v-col v-if="!token" :cols="isMobile ? 12 : 'auto'" class="pl-sm-5 pt-3 pt-sm-6">
          <c-btn
            color="primary"
            :block="isMobile"
            :loading="createTokenIsLoading"
            :label="$t('api.generate_new_token')"
            depressed
            @click="createToken()"
          />
        </v-col>
      </v-row>
    </v-container>
    <swagger v-if="!adNetworkSettingApiSelectIsUrl" class="mt-4" />
    <confirm-dialog ref="removeAcceptDialog" @confirm="removeToken()">
      <template #title>
        {{ $t('api.delete_token') }}
      </template>
      <template #body>
        {{ $t('api.delete_sure') }}
      </template>
    </confirm-dialog>
  </div>
</template>

<script>
  import { GTM_EVENTS } from '@clickadilla/components/constants/gtm-events.js'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { mapState } from 'vuex'
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import ConfirmDialog from '@/components/ConfirmDialog.vue'
  import { apiTokenRepository } from '@/services/repository-factory.js'
  import gtmPush from '@/services/utils/gtm-push.js'
  import Swagger from '@/views/Api/Swagger.vue'
  import CopyButton from '@/components/CopyButton.vue'
  import handleErrors from '@/services/handleErrors.js'

  export default {
    name: 'Api',
    components: {
      CopyButton,
      CTextField,
      Swagger,
      CBtn,
      ConfirmDialog
    },
    data() {
      return {
        createTokenIsLoading: false,
        removeTokenIsLoading: false
      }
    },
    computed: {
      ...mapState('settings', ['adNetwork']),
      token() {
        return this.$auth.user?.token
      },
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      adNetworkSettingApiSelectIsUrl() {
        return this.adNetwork.settings.apiSelect === 'url'
      },
      linkOrText() {
        return this.adNetworkSettingApiSelectIsUrl ? 'a' : 'span'
      }
    },
    methods: {
      async createToken() {
        this.createTokenIsLoading = true
        try {
          await apiTokenRepository.store()
          gtmPush({ event: GTM_EVENTS.CREATE_API_TOKEN })
          await this.refreshProfile()
        } catch (error) {
          handleErrors(error)
        }
        this.createTokenIsLoading = false
      },
      async removeToken() {
        this.removeTokenIsLoading = true
        try {
          await apiTokenRepository.destroy(this.token)
          await this.refreshProfile()
        } catch (error) {
          handleErrors(error)
        }
        this.removeTokenIsLoading = false
      },
      async refreshProfile() {
        await this.$auth.fetchUser()
      }
    }
  }
</script>

<template>
  <div :class="['white rounded', { 'mobile-wrapper': isMobile }]">
    <v-progress-linear
      :active="isLoading"
      indeterminate
      color="primary"
    />
    <div id="swagger-ui" />
  </div>
</template>

<script>
  import { apiTokenRepository } from '@/services/repository-factory.js'
  import handleErrors from '@/services/handleErrors.js'

  export default {
    name: 'Swagger',
    data() {
      return {
        api: null,
        isLoading: false
      }
    },
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      }
    },
    async mounted() {
      await this.fetchApi()
      if (this.api) {
        this.loadScript()
      }
    },
    methods: {
      async fetchApi() {
        this.isLoading = true
        try {
          this.api = await apiTokenRepository.swaggerApi()
        } catch (error) {
          handleErrors(error)
        }
        this.isLoading = false
      },
      loadScript() {
        return new Promise((resolve) => {
          const script = document.createElement('script')
          script.async = true
          script.setAttribute('src', 'https://unpkg.com/swagger-ui-dist@5.12.3/swagger-ui-bundle.js')
          script.onload = () => {
            window.ui = window.SwaggerUIBundle({
              spec: this.api,
              dom_id: '#swagger-ui'
            })
            resolve()
          }
          document.body.appendChild(script)
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import url('https://unpkg.com/swagger-ui-dist@5.12.3/swagger-ui.css');

  ::v-deep {
    .swagger-ui {
      .info {
        background-color: transparent !important;
      }
      .responses-inner {
        overflow-x: auto;
      }
      .highlight-code > .microlight code {
        white-space: pre !important;
      }
    }
  }
</style>
